/* You can add global styles to this file, and also import other style files */

/* Global styles */

/* fira-sans-regular - latin */
/*
@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 300;
    src: url('./assets/fira-sans-v8-latin-regular.woff') format('woff');
}*/

/* Material base style */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

html, body {
    font-family: "Fira Sans", sans-serif;

}

html {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: #EBEBEB;
}

body {
    color: white;
    margin: 0;
}

.bw-logo {
    top: 10px;
    left: 10px;
    width: 70px;
    height: 20px;
    margin: 10px;
}

.bw-product {
    color: #337ab7;
    font-family: "Fira sans", sans-serif;
    font-size: 14px;
    font-weight: normal;
    margin-left: 14px;
    float: left;
    margin-top: 12px;
}

.turn-off-mfa-container .mat-dialog-container {
    padding: 0px;
    overflow: hidden;
    padding-bottom: 20px;
}

.turn-off-mfa-container .mat-dialog-title {
    margin-left: 0px !important;
    margin-top: 20px !important;
    margin-bottom: 17.5px !important;
    height: 25px;
    font: 500 18px/30px Fira Sans;
    color: #444444;
}

.turn-off-mfa-container .mat-dialog-content {
    height: 40px;
    font: var(--unnamed-font-style-normal) normal normal var(--unnamed-font-size-14)/var(--unnamed-line-spacing-40) var(--unnamed-font-family-fira-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    font: 14px/20px Fira Sans;
    letter-spacing: 0px;
    color: #444444;
    height: auto;
    opacity: 1;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 16.5px !important;
    padding-top: 10px !important;
    padding-left: 0px;
}

.turn-off-mfa-container .mat-dialog-actions {
    float: right;
    margin-top: 30px;
}

.turn-off-mfa-container .agree-button {
    float: right;
    margin-top: -10px;
    border: none;
    font-weight: bold;
    background-color: transparent;
    background: #00A9CE 0% 0% no-repeat padding-box;
    border-radius: 3px;
    min-width: 100px;
    line-height: 0px;
    text-align: center;
    font: 500 14px/20px Fira Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-left: 10px;
    margin-right: 15px;
    border: 1px solid #00A9CE !important;
    height: 30px;
    cursor: pointer;
}

.turn-off-mfa-container .disagree-button {
    line-height: 0px;
    margin-top: -10px;
    border: none;
    min-width: 100px;
    letter-spacing: 0px;
    color: #00A9CE;
    font-weight: bold;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #00A9CE !important;
    border-radius: 3px;
    opacity: 1;
    height: 30px;
    text-align: center;
    font: 500 14px/20px Fira Sans;
    cursor: pointer;
}

.turn-off-mfa-container .disagree-button:focus {
    outline: none;
}

.turn-off-mfa-container .agree-button:focus {
    outline: none;
}

.dialog-line-separator {
    border-bottom-color: #00A9CE;
    border-bottom-style: solid;
    border-bottom-width: 3px;
}

.transparent-background{
    background: transparent;
}

.mat-expansion-panel-custom {
    box-sizing: content-box;
    display: block;
    margin: 0;
    border-radius: 4px;
    overflow: hidden;
    transition: margin 225ms cubic-bezier(0.4, 0, 0.2, 1),box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
}

.gt-titlebar-custom{
    background: #FFFFFF !important;
}

.footer-container{
    position: relative;
    top: -9px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 60px;
    background: #FAFAFA 0% 0% no-repeat padding-box;
    border-bottom-radius: 100px;
    opacity: 1;
}

.footer-text-new{
    height: 20px;
    font-size: 14px;
    text-align: left;
    letter-spacing: 0px;
    color: #707372;
    padding: 20px;
    opacity: 1;
}

.floatRight{
    float: right;
}